import React from "react";
import {graphql} from "gatsby";
import Markdown from 'markdown-to-jsx';
import parse from "html-react-parser";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import Card from "../../components/cards/Card";
import Gallery from "../../components/pages/about/Gallery";

import {Body, Header2, Span, Title} from "../../components/styled/typography/Typography";
import Col from "../../components/styled/grid/Col";

const CareerTemplate = ({data}) => {

    const {title_white, title_color, description, content, SEO} = data.jobs
    const {nodes} = data.images

    return (
        <Layout lang={"hu"} seo={SEO}>
            <SectionLayoutGrid customBackground="middle" customPadding="noBottom">
                <Col>
                    <Title color="white" marginBottom="small">
                        {title_white && title_white}<br/>
                        <Span color="primary-brand">{title_color}</Span>
                    </Title>
                    <Body color="body-text">
                        {parse(description)}
                    </Body>
                </Col>
                <Col>
                    <Markdown className="markdown" children={content}/>
                    <Header2 color="white" marginBottom="small" marginTop="medium">
                        Felkeltettük az érdeklődésed?
                    </Header2>
                    <Body bold color="body-text" marginBottom="small">
                        Ha úgy érzed, hogy szeretnél bővebben beszélgetni a pozícióról, küldd el önéletrajzod a <a href="mailto:hr@balasys.hu">hr@balasys.hu</a> címre.
                        A HR csapatunk napokon belül keresni fog téged, hogy a részleteket megbeszéljük, valamint Te is fel tudd tenni a kérdéseid. Ha számodra és számunkra is minden információ rendelkezésünkre áll, és szívesen dolgoznánk egymással, akkor már csak az adminisztráció van hátra.
                    </Body>
                    <Body color="body-text">
                        Tájékoztatunk, hogy jelentkezéseddel hozzájárulsz ahhoz, hogy az Információs önrendelkezési
                        jogról és az információszabadságról szóló 2011. évi CXII. törvény 5.§ (1) bekezdés a.) pontja,
                        valamint az Európai Unió Általános Adatvédelmi Rendelete – GDPR – az európai Parlament és Tanács
                        (EU) 2016/679. sz. rendelet 6. cikk (1) bekezdés a.) pontjának értelmében személyes adataidat,
                        valamint a beküldött, személyes adatokat tartalmazó dokumentumokat a pályázati eljárásban
                        megismerhessék és kezeljék.
                    </Body>
                </Col>
                <Col span={4} grid>
                    <Card
                        title={"Balasys sztori"}
                        description={"Nagy szakmai tudással és elhivatottsággal rendelkező csapatával a Balasysnak sikerült elérnie, hogy vezető szolgáltatójává váljon a proxy alapú digitalis technológiának."}
                    />
                </Col>
                <Col span={4} grid>
                    <Card
                        title={"Balasys csapat"}
                        description={"Csapatunkban szívesen látjuk az új tehetségeket, nagy nyitottsággal fogadjuk az innovatív ötleteket és nagy lelkesedéssel vonjuk be a kollégéinkat egyedi projektjeinkbe."}
                    />
                </Col>
                <Col span={4} grid>
                    <Card
                        title={"Balasys termékek"}
                        description={"Olyan termékekkel dolgozhatsz, melyek speciális és kihívásokkal teli problémákat oldanak meg."}
                    />
                </Col>
            </SectionLayoutGrid>
            <Gallery
                title={"Az irodánk"}
                images={nodes[0].gallery_images}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleJob($slug: String) {
         jobs: strapiJobs(slug: { eq: $slug }) {
            title_white
            title_color
            description
            content
            slug
            SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
            }
         }
         images: allStrapiAboutBalasys {
            nodes {
              gallery_images {
                id
                url
              }
            }
      }
   }
 `

export default CareerTemplate
